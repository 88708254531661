import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  DashboardOutlined,
  FundOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  DownSquareOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  FormOutlined,
  ContainerOutlined,
  AreaChartOutlined,
  MoneyCollectOutlined
} from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-home',
      path: `${APP_PREFIX_PATH}/dashboards/dashoardHome`,
      title: 'sidenav.dashboard.home',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-ecommerce',
      path: `${APP_PREFIX_PATH}/apps/ecommerce`,
      title: 'sidenav.apps.ecommerce',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [
        // {
        //   key: 'apps-ecommerce-productList',
        //   path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
        //   title: 'sidenav.apps.ecommerce.productList',
        //   icon: DownSquareOutlined,
        //   breadcrumb: true,
        //   submenu: []
        // },
        {
          key: 'apps-ecommerce-addProduct',
          path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
          title: 'sidenav.apps.ecommerce.addProduct',
          icon: PlusCircleOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-ecommerce-addgroceryProduct',
          path: `${APP_PREFIX_PATH}/apps/ecommerce/add-grocery-product`,
          title: 'sidenav.apps.ecommerce.add-grocery-product',
          icon: PlusCircleOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-ecommerce-listgroceryProduct',
          path: `${APP_PREFIX_PATH}/apps/ecommerce/list-grocery-product`,
          title: 'sidenav.apps.ecommerce.add-grocery-list',
          icon: UnorderedListOutlined,
          breadcrumb: false,
          submenu: []
        },
        // {
        //   key: 'apps-ecommerce-editProduct',
        //   path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/12`,
        //   title: 'sidenav.apps.ecommerce.editProduct',
        //   icon: EditOutlined,
        //   breadcrumb: false,
        //   submenu: []
        // },
        {
          key: 'apps-ecommerce-orders',
          path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
          title: 'sidenav.apps.ecommerce.orders',
          icon: FormOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-ecommerce-inventory',
          path: `${APP_PREFIX_PATH}/apps/ecommerce/inventory`,
          title: 'sidenav.apps.ecommerce.inventory',
          icon: AreaChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    }
  ]
}]

const ordersBoardNavTree = [{
  key: 'orders',
  path: `${APP_PREFIX_PATH}/orders`,
  title: 'sidenav.orders',
  icon: MoneyCollectOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'orders-invoice',
      path: `${APP_PREFIX_PATH}/orders/invoice`,
      title: 'sidenav.pages.invoice',
      icon: ContainerOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-ecommerce-payments',
      path: `${APP_PREFIX_PATH}/orders/payment`,
      title: 'sidenav.apps.ecommerce.payment',
      icon: FormOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...ordersBoardNavTree
]

export default navigationConfig;
